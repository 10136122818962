import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class Tactical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            mComp: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getTacticalData() {
        if(this.state.mComp.length !== 0) {
            let mCompanyName = this.state.mComp.company.name;
            let mRegNumber = this.state.mComp.company.registration_number;
            let mDate = (this.state.mComp.information.creation_time).replace("T", " ");
            let mProvince = this.state.mComp.company.province;
            let mIndustry = this.state.mComp.company.industry;
            let report_id = (this.state.mComp.information.report_id);
            let time_from = (this.state.mComp.information.time_from).replaceAll("T", " ").toUpperCase();
            let time_to = (this.state.mComp.information.time_to).replaceAll("T", " ").toUpperCase();

            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}} title={"Report: " + report_id} subTitle={time_from + " - " + time_to}></Card>
                </React.Fragment>
            );
        }
    }

    getFullTacticalData(mData) {
        if(mData.length !== 0) {
            let mDate = (mData.creation_time).replace("T", " ");
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}} title={"System: " + mData.system_name} subTitle={mDate}>
                        <p>{mData.message}</p>
                        <hr/>
                        <div className="p-md-12">
                            <p>System Name: {mData.system_name}</p>
                            <p>Source IP: {mData.information.src_ip}</p>
                            <p>Destination IP: {mData.information.dst_ip}</p>
                            <p>Session: {mData.information.session}</p>
                            <p>Log Format: {mData.information.log_format}</p>
                            <p>Sensor ID: {mData.sensor_id}</p>
                            <p>Event ID: {mData.event_id}</p>
                            <p>Log System: {mData.log_system}</p>
                            <p>Log Time: {mData.log_time}</p>
                        </div>
                    </Card>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.report !== undefined) {
            this.productServiceDataSources.getTacticalReportsInfo(this.props.location.report).then(data => this.setState({
                items: data.events,
                mComp: data
            }));
        }
    }

    render() {
        const data_id = this.props.location.report;
        const breadCrumbItems = [
            {label: 'Reporting', url: '/#/dashboard'},
            {label: 'Tactical List', url: '/#/tacticallist'},
            {label: data_id}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 global-sales">
                    <div className="card p-grid">
                        <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push('/tacticallist');
                        }} style={{marginRight: '5px'}}/>
                        <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                    </div>
                    <div>
                        {this.getTacticalData()}
                    </div>
                    <br/>
                    <div>
                        {this.state.items.map((data, index) => {
                            return (
                                <div className="card" key={index}>
                                    {this.getFullTacticalData(data)}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}