import React, { Component } from 'react';
import './Landing.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class Authkey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authorizationKey: ""
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    async nextPath(path) {
        this.props.history.push({
            pathname: path
        });
    }

    componentDidMount() {
        let resAuthKey = this.productServiceDataSources.getAuthKey();
        resAuthKey.then(result => {
            if( result !== undefined) {
                this.setState({ authorizationKey: result });
            }
        });
    }

    /**
     *
     * @returns
     * <div className="p-md-6 button-pane">
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Launch SCTIEP" onClick={() => {this.nextPath('/login')}} />
                    </div>
     */

    /**
     *
     * @returns
     *  <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Activate" onClick={(e) => {
                                e.preventDefault();
                            }} />
     */

    render() {
        return (
            <header>
                <div className="intro-logo jumbo-bg">
                    <h1>Cyber Threat Intelligence Exchange Platform</h1>
                    <div className="p-md-12">
                        <div className="p-md-12">
                            <p>Registration successful</p>
                            <p>Below is the key to activate your CTIEP product</p>
                            <h5>{this.state.authorizationKey}</h5>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
