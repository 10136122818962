import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export class MispId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getMispTagData(mData) {
        let misp_tag_color = mData.colour;
        let misp_tag_id = mData.id;
        let misp_tag_name = mData.name;
        let misp_tag_numerical_value = mData.numerical_value;
        let misp_tag_numerical_user_id = mData.user_id;
        return (
            <React.Fragment>
                <Card style={{'background': misp_tag_color}} title={"MISP TAG: " + misp_tag_id} subTitle={misp_tag_numerical_value}>
                    <p>Tag Name: {misp_tag_name}</p>
                    <p>Tag User: {misp_tag_numerical_user_id}</p>
                </Card>
            </React.Fragment>
        );
    }

    getMispAttributeData(mData) {
        let category =  mData.category ;
        let comment = mData.comment ;
        let distribution = mData.distribution ;
        let event_id = mData.event_id ;
        let id = mData.id ;
        let object_id = mData.object_id ;
        let sharing_group_id = mData.sharing_group_id ;
        let timestamp = mData.timestamp ;
        let type = mData.type ;
        let uuid = mData.uuid;
        let value = mData.value ;

        return (
            <React.Fragment>
                <Card title={"MISP Attribute: " + id} subTitle={"Type: " + type}>
                    <p>Category: {category}</p>
                    <p>Comment: {comment}</p>
                    <p>Distribution: {distribution}</p>
                    <p>Event Id: {event_id}</p>
                    <p>Object Id: {object_id}</p>
                    <p>Sharing Group Id: {sharing_group_id}</p>
                    <p>Timestamp: {timestamp}</p>
                    <p>Type: {type}</p>
                    <p>UUID: {uuid}</p>
                    <p>Value: {value}</p>
                </Card>
            </React.Fragment>
        );
    }

    getFormattedThreatLevel(mThreatLevel) {
        if(mThreatLevel === "High"){
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 165, 0, 1)',
                            width: '125px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        Threat Level: {mThreatLevel}
                    </p>
                </React.Fragment>
            );
        } else if(mThreatLevel === "Medium") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 200, 0, 1)',
                            width: '150px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        Threat Level: {mThreatLevel}
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(60, 179, 113, 1)',
                            width: '125px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        Threat Level: {mThreatLevel}
                    </p>
                </React.Fragment>
            );
        }
    }

    getGalaxy() {
        if(this.state.items.Galaxy.length !== 0) {
            return(
                <React.Fragment>
                    <h1>MISP Galaxy</h1>
                    <div className="card">
                        <DataTable value={this.state.items.Galaxy} selectionMode="single" dataKey="id">
                            <Column field="id" header="ID"></Column>
                            <Column field="name" header="Name"></Column>
                            <Column field="type" header="Type"></Column>
                            <Column field="description" header="Description"></Column>
                            <Column field="version" header="Version"></Column>
                        </DataTable>
                    </div>
                </React.Fragment>

            );
        }
    }

    getTimeFromStamp(mData) {
        let mTimestamp = mData.timestamp*1000;
        const myArray = (new Date(mTimestamp).toTimeString()).split("(");
        let myDate = myArray[0] + " ";
        return myDate + (new Date(mTimestamp).toLocaleTimeString());
    }

    getAttribute() {
        if(this.state.items.Attribute.length !== 0) {
            return(
                <React.Fragment>
                    <h1>MISP Attributes</h1>
                    <div className="card">
                        <DataTable value={this.state.items.Attribute} selectionMode="single" dataKey="id">
                            <Column field="type" header="Type"></Column>
                            <Column field="category" header="Category"></Column>
                            <Column field="timestamp" header="Timestamp" body={this.getTimeFromStamp}></Column>
                            <Column field="value" header="Value"></Column>
                        </DataTable>
                    </div>
                </React.Fragment>
            );
        }
    }

    getMispInfoById() {
        if(this.state.items.length !== 0) {
            let misp_analysis = this.state.items.analysis;
            let misp_id_only = this.state.items.id;
            let misp_attribute_count = this.state.items.attribute_count;
            let misp_date = this.state.items.date;
            let misp_distribution = this.state.items.distribution;
            let misp_info = this.state.items.info;
            let misp_org_id = this.state.items.org_id;
            let misp_orgc_id = this.state.items.orgc_id;
            let misp_publish_timestamp = (this.state.items.publish_timestamp).replace("T", " ");
            let misp_published = this.state.items.published;
            let misp_sharing_group_id = this.state.items.sharing_group_id;
            let misp_threat_level_id = this.state.items.threat_level_id;
            let misp_timestamp = (this.state.items.timestamp).replace("T", " ");
            let misp_uuid = this.state.items.uuid;
            let misp_id = this.state.items.id;
            let misp_id_uuid = misp_id + " | " + misp_uuid;
            let misp__id = this.state.items._id;
            let misp_org_name = this.state.items.Org.name;
            let misp_org_uuid = this.state.items.Org.uuid;
            let misp_orgc_name = this.state.items.Orgc.name;
            let misp_orgc_uuid = this.state.items.Orgc.uuid;

            let mThreat = '';
            if(misp_threat_level_id === "1") {
                mThreat = "High";
            } else if(misp_threat_level_id === "2") {
                mThreat = "Medium";
            } else if(misp_threat_level_id === "3") {
                mThreat = "Low";
            }

            return (
                <React.Fragment>
                    <Card style={{'background': '#FFFFFF'}} title={"MISP ID: " + misp_id_only} subTitle={misp_publish_timestamp}>
                        {this.getFormattedThreatLevel(mThreat)}
                        <hr/>
                        <h4>Data Description</h4>
                        <p>Analysis: {misp_analysis}</p>
                        <p>Attribute Count: {misp_attribute_count}</p>
                        <p>Date: {misp_date}</p>
                        <p>Base Severity: {misp_distribution}</p>
                        <p>Distribution: {misp_distribution}</p>
                        <p>Info: {misp_info}</p>
                        <p>Org Id: {misp_org_id}</p>
                        <p>Orgc Id: {misp_orgc_id}</p>
                        <p>Sharing Group Id: {misp_sharing_group_id}</p>
                        <p>Timestamp: {misp_timestamp}</p>
                        <p>Org Name: {misp_org_name}</p>
                        <p>Org UUID: {misp_org_uuid}</p>
                        <p>Orgc Name {misp_orgc_name}</p>
                        <p>Orgc UUID: {misp_orgc_uuid}</p>
                        <hr/>
                        <br/>
                        <p style={{'float': 'right'}}>{misp_id_uuid}</p>
                    </Card>
                    <br/>
                    <Card style={{'background': '#FFFFFF'}} title={"MISP TAGS"}>
                        {this.state.items.Tag.map((data, index) => {
                            return (
                                <div className="card" key={index}>
                                    {this.getMispTagData(data)}
                                </div>
                            )
                        })}
                    </Card>
                    <br/>
                    <div>
                        {this.getAttribute()}
                    </div>
                    <br/>
                    <div>
                        {this.getGalaxy()}
                    </div>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.MispData !== undefined) {
            this.productServiceDataSources.getMispIdSourcesInfo(this.props.location.MispData).then(data => this.setState({ items: data }));
        }
    }

    render() {
        const data_id = this.state.items.id;
        const breadCrumbItems = [
            {label: 'Data From Sources',  url: '/#/dashboard'},
            {label: 'MISP Data',  url: '/#/mispdata' },
            {label: data_id}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/mispdata');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home} className="p-col-10"/>
                </div>
                <div className="card">
                    {this.getMispInfoById()}
                </div>
            </div>
        );
    }
}