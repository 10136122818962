import React from 'react';
import { Chart } from 'primereact/chart';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import L, {Icon} from 'leaflet';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

export const Dashboard = () => {
    const mapPosition = [36.0682, -80.2928];
    const productServiceDataSources = new ServiceAllApiData();
    let mKeys = productServiceDataSources.getCveAggregationKeys();
    let mValues = productServiceDataSources.getCveAggregationValues();
    let mColors = productServiceDataSources.getCveAggregationColors();
    let mMispKeys = productServiceDataSources.getMispAggregationKeys();
    let mMispValues = productServiceDataSources.getMispAggregationValues();
    let mMispColors = productServiceDataSources.getMispAggregationColors();
    let mStatusKeys = productServiceDataSources.getUrlAggregationKeys();
    let mStatusValues = productServiceDataSources.getUrlAggregationValues();
    let mStatusColors = productServiceDataSources.getUrlAggregationColors();
    let mCoords = productServiceDataSources.getUrlAggregationCoords();

    const getUrlStatus = () =>
    {
        let mData = {
            labels: mStatusKeys,
            datasets: [
                {
                    data: mStatusValues,
                    backgroundColor: mStatusColors,
                    hoverBackgroundColor: mStatusColors
                }
            ]
        };

        let mOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
            },
            title: {
                text: "Blacklisted Url Status",
                display: true
            },
        };

        return (
            <React.Fragment>
                <Chart type="doughnut" data={mData} options={mOptions}/>
            </React.Fragment>
        );
    }

    const getCveItems = () =>
    {
        let totalCve = 0;
        for(let i = 0; i < mKeys.length; i++) {
            totalCve = totalCve + parseInt(mValues[i]);
        }
        return (
            <React.Fragment>
                <div className="overview-box-count">{totalCve}</div>
            </React.Fragment>
        );
    }

    const getCritical = () =>
    {
        let critVal = "0";
        for(let i = 0; i < mKeys.length; i++) {
            if(mKeys[i] === "CRITICAL") {
                critVal = mValues[i];
            }
        }
        return (
            <React.Fragment>
                <div className="overview-box-count">{critVal}</div>
            </React.Fragment>
        );
    }

    const getHigh = () =>
    {
        let highVal = "0";
        for(let i = 0; i < mKeys.length; i++) {
            if(mKeys[i] === "HIGH") {
                highVal = mValues[i];
            }
        }
        return (
            <React.Fragment>
                <div className="overview-box-count">{highVal}</div>
            </React.Fragment>
        );
    }

    const getMedium = () =>
    {
        let medVal = "0";
        for(let i = 0; i < mKeys.length; i++) {
            if(mKeys[i] === "MEDIUM") {
                medVal = mValues[i];
            }
        }
        return (
            <React.Fragment>
                <div className="overview-box-count">{medVal}</div>
            </React.Fragment>
        );
    }

    const getLow = () =>
    {
        let lowVal = "0";
        for(let i = 0; i < mKeys.length; i++) {
            if(mKeys[i] === "LOW") {
                lowVal = mValues[i];
            }
        }
        return (
            <React.Fragment>
                <div className="overview-box-count">{lowVal}</div>
            </React.Fragment>
        );
    }

    const getInfo = () =>
    {
        let infoVal = "0";
        for(let i = 0; i < mKeys.length; i++) {
            if(mKeys[i] === "INFO") {
                infoVal = mValues[i];
            }
        }
        return (
            <React.Fragment>
                <div className="overview-box-count">{infoVal}</div>
            </React.Fragment>
        );
    }

    const getUrlLocations = () =>
    {
        if(mCoords.length !== 0) {
            return (
                <MapContainer center={mapPosition} zoom={3} scrollWheelZoom={false} style={{ height:550, width:"100%"}}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {mCoords.map((data, index) => {
                        return (
                            <Marker
                                key={index}
                                position={{ lat: data.lat, lng: data.lon }}
                                icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                            >
                                <Popup>
                                    <span>{"Latitude: " + data.lat + ", Longitude: " + data.lon}</span>
                                    <br/>
                                    <span>{"Country: " + data.country + ", City; " + data.city}</span>
                                    <br/>
                                    <span>{"Detections: " + data.detect + ", IP; " + data.ip}</span>
                                </Popup>
                            </Marker>
                        );})
                    }
                </MapContainer>
            );
        } else {
            console.log("Coordinates are not available");
        }
    }

    const getFullSeverityReport = () =>
    {
        let plotSeverities = {
            labels: mKeys,
            datasets: [
                {
                    data: mValues,
                    backgroundColor: mColors
                }
            ]
        };

        return (
            <React.Fragment>
                <div className="p-md-12">
                    <Chart
                        type="bar"
                        data={plotSeverities}
                        options={{
                            legend: {
                                display: false
                            },
                            responsive:true,
                            title: { text: "CVE Incident Severity Distribution", display: true },
                            scales:{
                                yAxes:[{
                                    ticks:{
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );

    }

    const getMispAggregation = () =>
    {
        let plotMispData = {
            labels: mMispKeys,
            datasets: [
                {
                    data: mMispValues,
                    backgroundColor: mMispColors
                }
            ]
        };

        return (
            <React.Fragment>
                <div className="p-md-12">
                    <Chart
                        type="bar"
                        data={plotMispData}
                        options={{
                            legend: {
                                display: false
                            },
                            responsive:true,
                            title: { text: "MISP Threat Level Distribution", display: true },
                            scales:{
                                yAxes:[{
                                    ticks:{
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="p-grid dashboard">
            <div className="p-col-12 p-md-6 p-lg-2">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="pi pi-ticket"></i>
                        <span>CVE Items</span>
                    </div>
                    {getCveItems()}
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-2">
                <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(255, 0, 0, 1)'}}>
                    <div className="overview-box-title">
                        <span>Critical</span>
                    </div>
                    {getCritical()}
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-2">
                <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(255, 165, 0, 1)'}}>
                    <div className="overview-box-title">
                        <span>High</span>
                    </div>
                    {getHigh()}
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-2">
                <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(255, 200, 0, 1)'}}>
                    <div className="overview-box-title">
                        <span>Medium</span>
                    </div>
                    {getMedium()}
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-2">
                <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(60, 179, 113, 1)'}}>
                    <div className="overview-box-title">
                        <span>Low</span>
                    </div>
                    {getLow()}
                </div>
            </div>
            <div className="p-col-10 p-md-6 p-lg-2">
                <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(0, 0, 255, 1)'}}>
                    <div className="overview-box-title">
                        <span>Info</span>
                    </div>
                    {getInfo()}
                </div>
            </div>

            <div className="p-col-12 p-lg-4 global-sales">
                <div className="card">
                    {getMispAggregation()}
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-4 global-sales">
                <div className="card">
                    {getUrlStatus()}
                </div>
            </div>

            <div className="p-col-12 p-lg-4 global-sales">
                <div className="card">
                    {getFullSeverityReport()}
                </div>
            </div>

            <div className="p-col-12">
                <div className="card">
                    <h5>Blacklisted Url Locations</h5>
                    {getUrlLocations()}
                </div>
            </div>
        </div>
    );
}
