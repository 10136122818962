import React, { Component } from 'react';
import './Landing.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';

export class Failure extends Component {
    async nextPath(path) {
        this.props.history.push({
            pathname: path
        });
    }

    render() {
        return (
            <header>
                <div className="intro-logo jumbo-bg">
                    <h1>Cyber Threat Intelligence Exchange Platform</h1>
                    <div className="rowToColumn p-md-12">
                        <div className="p-md-12">
                            <p>Login failed....</p>
                        </div>
                    </div>
                    <div className="p-md-6 button-pane">
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Retry" onClick={() => {this.nextPath('/login')}} />
                        <div className="divider"/>
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Home" onClick={() => {this.nextPath('/')}} />
                    </div>
                </div>
            </header>
        );
    }
}
