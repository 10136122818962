import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class Malware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectedItem: null,
        };

        this.productServiceDataSources = new ServiceAllApiData();
        this.onRowSelect = this.onRowSelect.bind(this);
    }

    onRowSelect(event) {
        this.nextPath('/malwareid', event.data.id);
    }

    nextPath(path, malwareId) {
        this.props.history.push({
            pathname: path,
            cveData: malwareId
        });
    }

    getFormattedTime(thisState) {
        let mDate = (thisState.first_seen_utc).replace("T", " ");
        return (
            <React.Fragment>
                <p>{mDate}</p>
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.productServiceDataSources.getMalwareDataSourcesInfo().then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Data From Sources', url: '/#/dashboard'},
            {label: 'Malware'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/malware');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Malware</h1>
                <div className="card">
                    <DataTable value={this.state.items} selectionMode="single" selection={this.state.selectedItem} onSelectionChange={e => this.setState({ selectedItem: e.value })}
                        dataKey="id" onRowSelect={this.onRowSelect}>
                        <Column field="file_name" header="File Name"></Column>
                        <Column field="mime_type" header="Mime Type"></Column>
                        <Column field="reporter" header="Reporter"></Column>
                        <Column field="first_seen_utc" header="Date" body={this.getFormattedTime}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}