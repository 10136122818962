import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export class ManageMisp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceNotifications = new ServiceAllApiData();
    }

    componentDidMount() {
        this.productServiceNotifications.getNotifications().then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Topology', url: '/#/dashboard'},
            {label: 'Manage MISP'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Manage MISP</h1>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">MISP Url</label>
                                <div className="login-input">
                                    <InputText placeholder="url"/>
                                </div>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">MISP Key</label>
                                <div className="login-input">
                                    <InputText placeholder="Key"/>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-2 button-pane">
                                <Button label="Submit" onClick={(e) => {
                                    e.preventDefault();
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
