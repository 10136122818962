import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import L, {Icon} from 'leaflet';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'leaflet/dist/leaflet.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import './Local.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

export class ThreatVisualizer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            sortOptions: [{ header: "Number of Detections", desc: true }]
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getUrlStatus () {
        let mStatusKeys = this.productServiceDataSources.getUrlAggregationKeys();
        let mStatusValues = this.productServiceDataSources.getUrlAggregationValues();
        let mStatusColors = this.productServiceDataSources.getUrlAggregationColors();

        let mData = {
            labels: mStatusKeys,
            datasets: [
                {
                    data: mStatusValues,
                    backgroundColor: mStatusColors,
                    hoverBackgroundColor: mStatusColors
                }
            ]
        };

        let mOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
            },
            title: {
                text: "Blacklisted Url Status",
                display: true
            },
        };

        return (
            <React.Fragment>
                <Chart type="doughnut" data={mData} options={mOptions}/>
            </React.Fragment>
        );
    }

    getUrlLocations () {
        let mCoords = this.productServiceDataSources.getUrlAggregationCoords();
        const mapPosition = [36.0682, -80.2928];
        if(mCoords.length !== 0) {
            return (
                <MapContainer center={mapPosition} zoom={3} scrollWheelZoom={false} style={{ height:550, width:"100%"}}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {mCoords.map((data, index) => {
                        return (
                            <Marker
                                key={index}
                                position={{ lat: data.lat, lng: data.lon }}
                                icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                            >
                                <Popup>
                                    <span>{"Latitude: " + data.lat + ", Longitude: " + data.lon}</span>
                                    <br/>
                                    <span>{"Country: " + data.country + ", City; " + data.city}</span>
                                    <br/>
                                    <span>{"Detections: " + data.detect + ", IP; " + data.ip}</span>
                                </Popup>
                            </Marker>
                        );})
                    }
                </MapContainer>
            );
        } else {
            console.log("Coordinates are not available");
        }
    }



    componentDidMount() {
        let mCoords = this.productServiceDataSources.getUrlAggregationCoords();
        if(mCoords !== undefined) {
            this.setState({
                items: mCoords
            });
        }
    }

    render() {
        const breadCrumbItems = [
            {label: 'Risk Management', url: '/#/dashboard'},
            {label: 'Threat Visualizer'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };
        let mCoords = this.productServiceDataSources.getUrlAggregationCoords();
        let mStatusValues = this.productServiceDataSources.getUrlAggregationValues();

        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/dashboard");
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <div className="p-col-12 p-grid">
                    <h1>Threat IoC Visualizer</h1>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="p-grid overview-box overview-box-1">
                        <div className="overview-box-title">
                            <i className="pi pi-map"></i>
                            <span>Most Detections:</span>
                            <span>{mCoords[0].country} - {mCoords[0].city}</span>
                        </div>
                        Detections: {mCoords[0].detect}
                        <br/>
                        IP Address: {mCoords[0].ip}
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(60, 179, 113, 1)'}}>
                        <div className="overview-box-title">
                            <span>Online</span>
                        </div>
                       {mStatusValues[0]}
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="p-grid overview-box overview-box-1" style={{'backgroundColor': 'rgba(255, 0, 0, 1)'}}>
                        <div className="overview-box-title">
                            <span>Offline</span>
                        </div>
                        {mStatusValues[1]}
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                    <div className="card">
                        <h3>Blacklisted Url Status</h3>
                        {this.getUrlStatus()}
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                    <div className="card">
                        <h5>Blacklisted Url Locations</h5>
                        {this.getUrlLocations()}
                    </div>
                </div>
                <br/>
                <div className="card">
                    <h1>Threat Actor Locations</h1>
                    <DataTable value={this.state.items} selectionMode="single" sorted={this.state.sortOptions} responsiveLayout="scroll">
                        <Column field="country" header="Country" style={{ textAlign: "left" }}></Column>
                        <Column field="city" header="City"></Column>
                        <Column field="ip" header="IP Address"></Column>
                        <Column field="detect" header="Number of Detections" sortable style={{ textAlign: "center" }}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}