
import React, { Component } from 'react';
import 'primeicons/primeicons.css';
import './Registration.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

export class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUserName: '',
            mFirstName: '',
            mLastName: '',
            mUserEmail: '',
            mCellNumber: '',
            mCompanyAdmin: '',
            mCompanyname: '',
            mRegNumber: '',
            mEmail: '',
            selectedIndustry: '',
            mSelectedProvince: '',
            mPhyCity: '',
            mPostalCode:'',
            mCompany:'',
            industry_items: [{
                name: '',
                code: ''
            }],
            province_items: [{
                name: '',
                code: ''
            }],
            reportsBusy: false
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    async setSelectedIndustry(industryString) {
        this.setState({selectedIndustry: industryString});
    }

    async setSelectedProvince(provinceString) {
        this.setState({mSelectedProvince: provinceString});
    }

    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success Message', detail:'Successfully generated the required reports!', life: 5000});
    }

    showError() {
        this.toast.show({severity:'error', summary: 'Error Message', detail:'Unsuccessful in generating the required reports!', life: 5000});
    }

    isProgressBar() {
        if(this.state.reportsBusy === true) {
            return (
                <React.Fragment>
                    <div style={{float: "left"}}>
                        <ProgressSpinner/>
                    </div>
                    <div style={{float: "left", clear: "left"}}>
                        <p className="welcome-text" style={{color: "white"}}>Busy Signing Up</p>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="form-box">
                        <div className="body-form">
                            <form name="Form">
                                <div className="p-grid" >
                                    <div className="p-col-3" style={{ textAlign: 'left' }}>
                                        <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                                    </div>
                                    <div className="p-col-9" style={{ textAlign: 'left' }}>
                                        <h2 className="welcome-text">Sign Up</h2>
                                    </div>
                                    <div className="p-col-12">
                                        <fieldset>
                                            <legend>User:</legend>
                                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*User Name</small>
                                                    <InputText required id="userName" className="p-col-12 flatLookInputText" placeholder="User Name" value={this.state.mUserName} onChange={(e) => this.setState({mUserName: e.target.value})} />
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*First Name</small>
                                                    <InputText required id="firstName" className="p-col-12 flatLookInputText" placeholder="First Name" value={this.state.mFirstName} onChange={(e) => this.setState({mFirstName: e.target.value})} />
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Last Name</small>
                                                    <InputText required id="lastName" className="p-col-12 flatLookInputText" placeholder="Last Name" value={this.state.mLastName} onChange={(e) => this.setState({mLastName: e.target.value})} />
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Email</small>
                                                    <InputText required id="email1" type="email" className="p-col-12 flatLookInputText" placeholder="Email@example.com" value={this.state.mUserEmail} onChange={(e) => this.setState({mUserEmail: e.target.value})} />
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Cell Number</small>
                                                    <InputText required id="cellNumber" className="p-col-12 flatLookInputText" placeholder="Cell Number" value={this.state.mCellNumber} onChange={(e) => this.setState({mCellNumber: e.target.value})} />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Company:</legend>
                                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Company Name</small>
                                                    <InputText required id="companyName" className="p-col-12 flatLookInputText" placeholder="Company Name" value={this.state.mCompanyname} onChange={(e) => this.setState({mCompanyname: e.target.value})} />
                                                    <small className="p-error p-d-block">*Reg Number</small>
                                                    <InputText required id="regNumber" className="p-col-12 flatLookInputText" placeholder="Reg Number" value={this.state.mRegNumber} onChange={(e) => this.setState({mRegNumber: e.target.value})} />
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Email</small>
                                                    <InputText required id="email2" type="email" className="p-col-12 flatLookInputText" placeholder="Email@example.com" value={this.state.mEmail} onChange={(e) => this.setState({mEmail: e.target.value})} />
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Industry</small>
                                                    <Dropdown required id="industry" className="p-col-12 flatLookInputText" value={this.state.selectedIndustry} options={this.state.industry_items} onChange={(e) => this.setSelectedIndustry(e.target.value)} optionLabel="name" placeholder="Select Industry"/>
                                                </div>
                                                <div className="login-input">
                                                    <small className="p-error p-d-block">*Province</small>
                                                    <Dropdown required id="province" className="p-col-12 flatLookInputText" value={this.state.mSelectedProvince} options={this.state.province_items} onChange={(e) => this.setSelectedProvince(e.target.value)} optionLabel="name" placeholder="Select Province"/>
                                                    <small className="p-error p-d-block">*City</small>
                                                    <InputText required id="city" className="p-col-12 flatLookInputText" placeholder="City" value={this.state.mPhyCity} onChange={(e) => this.setState({mPhyCity: e.target.value})} />
                                                    <small className="p-error p-d-block">*Postal Code</small>
                                                    <InputText required id="postalCode" className="p-col-12 flatLookInputText" placeholder="PostalCode" value={this.state.mPostalCode} onChange={(e) => this.setState({mPostalCode: e.target.value})} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="p-col-12 button-pane" style={{ textAlign: 'center' }}>
                                        <Button label="Register" onClick={ (e) => {
                                            e.preventDefault();
                                            this.nextPath('/authkey', '/');
                                        }} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

    async nextPath(path, prev) {
        const registration = {
            "username": this.state.mUserName,
            "first_name": this.state.mFirstName,
            "last_name": this.state.mLastName,
            "password": "RandomPassword123",
            "password2": "RandomPassword123",
            "email": this.state.mUserEmail,
            "contact_number": this.state.mCellNumber,
            "is_active": true,
            "is_company_admin": true,
            "company": {
                "name": this.state.mCompanyname,
                "registration_number": this.state.mRegNumber,
                "physical_address1": "Any random string",
                "physical_address2": "Any random string",
                "physical_address3": "Any random string",
                "city": this.state.mPhyCity,
                "postal_code": this.state.mPostalCode,
                "email": this.state.mEmail,
                "website": "wwww.web.com",
                "industry": this.state.selectedIndustry.code,
                "province": this.state.mSelectedProvince.code
            }
        };

        this.setState({reportsBusy: true});
        this.productServiceDataSources.doRegistration(registration);
        setTimeout(() => {
            if(this.productServiceDataSources.getRegResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
                this.props.history.push({
                    pathname: path
                });
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 10000);
    }

    componentDidMount() {
        this.productServiceDataSources.getIndustriesInfo().then(data => {
            for(var i = 0; i < data.length; i++) {
                this.setState({
                    industry_items: [...this.state.industry_items, {
                        name: data[i].industry_name,
                        code: data[i].id
                    }]
                });
            }
        });
        this.productServiceDataSources.getProvincesInfo().then(data => {
            for(var i = 0; i < data.length; i++) {
                this.setState({
                    province_items: [...this.state.province_items, {
                        name: data[i].province_name,
                        code: data[i].id
                    }]
                });
            }
        });
    }

    render() {
        return(
            <div className="container">
                <Toast ref={(el) => this.toast = el} />
                <Toast ref={(el) => this.toastTL = el} position="top-left" />
                <Toast ref={(el) => this.toastBL = el} position="bottom-left" />
                <Toast ref={(el) => this.toastBR = el} position="bottom-right" />
                <Toast ref={(el) => this.toastBC = el} position="bottom-center" />
                {this.isProgressBar()}
            </div>
        )
    }
}