import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class Blacklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
        this.onRowSelect = this.onRowSelect.bind(this);
    }

    onRowSelect(event) {
        this.nextPath('/blacklistid', event.data.id);
    }

    nextPath(path, blacklistId) {
        this.props.history.push({
            pathname: path,
            cveData: blacklistId
        });
    }

    getFormattedTime(thisState) {
        let mDate = (thisState.date_added).replace("T", " ");
        return (
            <React.Fragment>
                <p>{mDate}</p>
            </React.Fragment>
        );
    }

    getFormattedStatus(thisState) {
        let mStatus = (thisState.url_status);
        if(mStatus === "online"){
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(60, 179, 113, 1)',
                            width: '50px',
                            borderRadius: '10%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {mStatus}
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 0, 0, 1)',
                            width: '50px',
                            borderRadius: '10%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {mStatus}
                    </p>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        this.productServiceDataSources.getBlacklistDataSourcesInfo().then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Data From Sources', url: '/#/dashboard'},
            {label: 'Blacklist'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Blacklisted Url List</h1>
                <div className="card">
                    <DataTable value={this.state.items} selectionMode="single" selection={this.state.selectedItem} onSelectionChange={e => this.setState({ selectedItem: e.value })}
                        dataKey="id" onRowSelect={this.onRowSelect}>
                        <Column field="url" header="Url"></Column>
                        <Column field="url_status" header="Url Status" body={this.getFormattedStatus}></Column>
                        <Column field="reporter" header="Reporter"></Column>
                        <Column field="date_added" header="Date Added" body={this.getFormattedTime}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}