import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class ManageNids extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceNotifications = new ServiceAllApiData();
    }

    componentDidMount() {
        this.productServiceNotifications.getNotifications().then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Topology', url: '/#/dashboard'},
            {label: 'Manage NIDS'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Manage NIDS</h1>
            </div>
        );
    }
}
