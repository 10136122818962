import React, { Component }  from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import axios from 'axios';
import './Login.css';

export class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUsername: '',
            mPassword: '',
            mPasswordConfirm: ''
        };
    }

    async nextPath(path) {
        const register = {'username': this.state.mUsername, 'email': this.state.mUsername, 'password1': this.state.mPassword, 'password2': this.state.mPasswordConfirm};
        let mResult = '';
        await axios.post('https://ctiepapi.urithi.co.za/capi/v1/rest-auth/registration/', register).then(res => {mResult = res.request.status});
        if(mResult === 200) {
            this.props.history.push({
                pathname: path
            });
        } else {
            this.props.history.push({
                pathname: this
            });
        }
    }

    render() {
        return (
            <div className="login-body">
                <div className="login-hero"></div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-3" style={{ textAlign: 'left' }}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                            </div>
                            <div className="p-col-9" style={{ textAlign: 'left' }}>
                                <h2 className="welcome-text">Register New User</h2>
                                <span className="guest-sign-in">Register on CTIEP</span>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText type='email' placeholder="user@example.com" value={this.state.mUsername} onChange={(e) => this.setState({mUsername: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Password</label>
                                <div className="login-input">
                                    <Password placeholder="Password" value={this.state.mPassword} onChange={(e) => this.setState({mPassword: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Confirm Password</label>
                                <div className="login-input">
                                    <Password placeholder="Password" value={this.state.mPasswordConfirm} onChange={(e) => this.setState({mPasswordConfirm: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-12 button-pane">
                                <Button label="Sign Up" onClick={() => {this.nextPath('/')}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
