import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class CveData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getFormattedSeverity(baseSeverity) {
        if(baseSeverity === "HIGH") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 165, 0, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {baseSeverity}
                    </p>
                </React.Fragment>
            );
        } else if(baseSeverity === "MEDIUM") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 200, 0, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {baseSeverity}
                    </p>
                </React.Fragment>
            );
        } else if(baseSeverity === "LOW") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(60, 179, 113, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {baseSeverity}
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(0, 0, 255, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        UNDEFINED
                    </p>
                </React.Fragment>
            );
        }
    }

    getCve(mData) {
        let mCveId = mData.cve.cve_data_meta.cve_id;
        let mCveDescription = mData.cve.description.description_data[0].value;
        let mDate = (mData.publishedDate).replace("T", " ");
        let mScore = "Impact score: " + mData.impact.baseMetricV3.impactScore;
        let xScore = "Exploitability score: " + mData.impact.baseMetricV3.exploitabilityScore;
        let mScores = mScore + " | " + xScore;
        let v3Scores = "Base Score: " + mData.impact.baseMetricV3.cvssV3.baseScore
        let v3Sev = this.getFormattedSeverity(mData.impact.baseMetricV3.cvssV3.baseSeverity);
        return (
            <React.Fragment>
                <Card style={{'background': '#F0F3F6'}} title={mCveId} subTitle={mDate}>
                    {mCveDescription}
                    <hr/>
                    <br/>
                    <p style={{'float': 'left'}}>{v3Scores}</p>
                    <p style={{'float': 'right'}}>{v3Sev}</p>
                </Card>
            </React.Fragment>
        );
    }

    nextPath(path, cveId) {
        this.props.history.push({
            pathname: path,
            cveData: cveId
        });
    }

    componentDidMount() {
        this.productServiceDataSources.getCveDataSourcesInfo(this.state.session).then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Data From Sources', url: '/#/dashboard'},
            {label: 'CVEs'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Common Vulnerabilities and Exposures</h1>
                {this.state.items.map((data, index) => {
                    return (
                        <div className="card" key={index} onClick={() => this.nextPath('/cveid', data.id)} style={{cursor: 'pointer'}}>
                            {this.getCve(data)}
                        </div>
                    )
                })}
            </div>
        );
    }
}