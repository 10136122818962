import React, { useState, useEffect } from 'react';
//import { Chart } from 'primereact/components/chart/Chart';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const Health = () => {
    const [userDBData, setUserDBData]  = useState({});
    const [userCPUData, setUserCPUData]  = useState({});
    const [userStateData, setUserStateData]  = useState({});
    const [userTimeData, setUserTimeData]  = useState({});

    const bdColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ];

    const severity = [
        'rgba(255, 0, 0, 1)',
        'rgba(255, 165, 0, 1)',
        'rgba(255, 200, 0, 1)',
        'rgba(60, 179, 113, 1)',
        'rgba(0, 0, 255, 1)',
    ];

    const bgColor = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#1FDE56"
    ];

    const bdWidth = 1;

    var sys_health_data = {
        method: 'GET',
        url: '../assets/demo/data/health-data.json',
    };

    const circleGraphSupport = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const mLabel = {
        indexLabelPlacement: 'inside'
    };

    const DBErrorApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_db_x = [];
            var items_db_y = [];
            for(let i = 0; i < res.data.data_base.length; i++) {
                items_db_x.push(res.data.data_base[i].severity);
                items_db_y.push(res.data.data_base[i].value);
            }

            setUserDBData({
                labels: items_db_x,
                datasets: [
                {
                    data: items_db_y,
                    backgroundColor: severity,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: severity
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const CPUsageApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_cpu_x = [];
            var items_cpu_y = [];
            for(let i = 0; i < res.data.data_cpu.length; i++) {
                items_cpu_x.push(res.data.data_cpu[i].app);
                items_cpu_y.push(res.data.data_cpu[i].value);
            }

            setUserCPUData({
                labels: items_cpu_x,
                datasets: [
                {
                    data: items_cpu_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: bgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const ServerStateApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_state_x = [];
            var items_state_y = [];
            for(let i = 0; i < res.data.data_state.length; i++) {
                items_state_x.push(res.data.data_state[i].source);
                items_state_y.push(res.data.data_state[i].value);
            }

            setUserStateData({
                labels: items_state_x,
                datasets: [
                {
                    data: items_state_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: bgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const UpDownTimeApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_time_x = [];
            var items_time_y = [];
            for(let i = 0; i < res.data.data_time.length; i++) {
                items_time_x.push(res.data.data_time[i].state);
                items_time_y.push(res.data.data_time[i].value);
            }

            setUserTimeData({
                labels: items_time_x,
                datasets: [
                {
                    data: items_time_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: bgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    useEffect(() => {
        DBErrorApi();
        CPUsageApi();
        ServerStateApi();
        UpDownTimeApi();
    }, []);

    let history = useHistory();
    const breadCrumbItems = [
        {label: 'Administration', url: '/#/dashboard'},
        {label: 'Health Status'}
    ];
    const home = { icon: 'pi pi-home', url: '/#/dashboard' };

    return (
        <div className="p-grid dashboard">
            <div className="p-col-12 p-lg-12">
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>System Health Status</h1>
            </div>
            <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                <div className="card">
                    <h5>Application CPU Usage</h5>
                    <Chart type="polarArea" data={userCPUData} options={circleGraphSupport}/>
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                <div className="card">
                    <h5>Data Source Server State</h5>
                    <Chart type="pie" data={userStateData} options={circleGraphSupport}/>
                </div>
            </div>
        </div>
    );
}
