import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class CveId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getFormattedSeverity(baseSeverity) {
        if(baseSeverity === "HIGH") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 165, 0, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {baseSeverity}
                    </p>
                </React.Fragment>
            );
        } else if(baseSeverity === "MEDIUM") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 200, 0, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {baseSeverity}
                    </p>
                </React.Fragment>
            );
        } else if(baseSeverity === "LOW") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(60, 179, 113, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {baseSeverity}
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(0, 0, 255, 1)',
                            width: '95px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        UNDEFINED
                    </p>
                </React.Fragment>
            );
        }
    }

    getCveInfoById() {
        if(this.state.items.length !== 0) {
            let mCveId = this.state.items.cve.cve_data_meta.cve_id;
            let mDataVersion = this.state.items.cve.data_version;
            let mDataFormat = this.state.items.cve.data_format;
            let mDataType = this.state.items.cve.data_type;
            let mCveDescription = this.state.items.cve.description.description_data[0].value;
            let mDate = (this.state.items.publishedDate).replace("T", " ");
            let mScore = "Impact score: " + this.state.items.impact.baseMetricV3.impactScore;
            let xScore = "Exploitability score: " + this.state.items.impact.baseMetricV3.exploitabilityScore;
            let mScores = this.state.items.cve.cve_data_meta.assigner + " | " + mScore + " | " + xScore;
            let mReference = this.state.items.cve.references.reference_data[0].name;
            let mAccessComplexity = this.state.items.impact.baseMetricV2.cvssV2.accessComplexity;
            let mAccessVector = this.state.items.impact.baseMetricV2.cvssV2.accessVector;
            let mAuthentication = this.state.items.impact.baseMetricV2.cvssV2.authentication;
            let mAvailabilityImpact = this.state.items.impact.baseMetricV2.cvssV2.availabilityImpact;
            let mBaseScore = this.state.items.impact.baseMetricV2.cvssV2.baseScore;
            let mConfidentialityImpact = this.state.items.impact.baseMetricV2.cvssV2.confidentialityImpact;
            let mIntegrityImpact = this.state.items.impact.baseMetricV2.cvssV2.integrityImpact;
            let mVectorString = this.state.items.impact.baseMetricV2.cvssV2.vectorString;
            let mVersion = this.state.items.impact.baseMetricV2.cvssV2.version;
            let zAttackComplexity = this.state.items.impact.baseMetricV3.cvssV3.attackComplexity;
            let zAttackVector= this.state.items.impact.baseMetricV3.cvssV3.attackVector;
            let zAvailabilityImpact = this.state.items.impact.baseMetricV3.cvssV3.availabilityImpact;
            let zBaseScore = this.state.items.impact.baseMetricV3.cvssV3.baseScore;
            let zBaseSeverity = this.state.items.impact.baseMetricV3.cvssV3.baseSeverity;
            let zConfidentialityImpact = this.state.items.impact.baseMetricV3.cvssV3.confidentialityImpact;
            let zIntegrityImpact = this.state.items.impact.baseMetricV3.cvssV3.integrityImpact;
            let zPrivilegesRequired = this.state.items.impact.baseMetricV3.cvssV3.privilegesRequired;
            let zScope = this.state.items.impact.baseMetricV3.cvssV3.scope;
            let zUserInteraction = this.state.items.impact.baseMetricV3.cvssV3.userInteraction;
            let zVectorString = this.state.items.impact.baseMetricV3.cvssV3.vectorString;
            let zVersion = this.state.items.impact.baseMetricV3.cvssV3.version;

            return (
                <React.Fragment>
                    <Card style={{'background': '#FFFFFF'}} title={mCveId} subTitle={mDate}>
                        {this.getFormattedSeverity(zBaseSeverity)}
                        <hr/>
                        <h4>Data Description</h4>
                        <h5>Details</h5>
                        {mCveDescription}
                        <p>Base Score {zBaseScore}</p>
                        <p>Base Severity: {zBaseSeverity}</p>
                        <br /> <br />
                        <p>Data Version: {mDataVersion}</p>
                        <p>Data Format: {mDataFormat}</p>
                        <p>Data Type: {mDataType}</p>
                        <hr/>
                        <br/>
                        <h4>CVSS 2 Impact</h4>
                        <p>Access Complexity: {mAccessComplexity}</p>
                        <p>Access Vector: {mAccessVector}</p>
                        <p>Authentication: {mAuthentication}</p>
                        <p>Availability Impact: {mAvailabilityImpact}</p>
                        <p>Base Score: {mBaseScore}</p>
                        <p>Confidentiality Impact: {zConfidentialityImpact}</p>
                        <p>Integrity Impact: {zIntegrityImpact}</p>
                        <p>Vector String: {mVectorString}</p>
                        <p>Version: {zVersion}</p>
                        <hr/>
                        <br/>
                        <h4>CVSS 3 Impact</h4>
                        <p>Attack Complexity: {zAttackComplexity}</p>
                        <p>Attack Vector: {zAttackVector}</p>
                        <p>Availability Impact: {zAvailabilityImpact}</p>
                        <p>Base Score {zBaseScore}</p>
                        <p>Base Severity: {zBaseSeverity}</p>
                        <p>Confidentiality Impact: {mConfidentialityImpact}</p>
                        <p>Integrity Impact: {mIntegrityImpact}</p>
                        <p>Score: {zScope}</p>
                        <p>User Interaction: {zUserInteraction}</p>
                        <p>Vector String: {zVectorString}</p>
                        <p>Privileges Required: {zPrivilegesRequired}</p>
                        <p>Version: {mVersion}</p>
                        <hr/>
                        <br/>
                        <h4>References</h4>
                        {mReference}
                        <hr/>
                        <br/>
                        <p style={{'float': 'right'}}>{mScores}</p>
                    </Card>
                </React.Fragment>
            );
        }
    }

    getHeading() {
        if(this.state.items.length !== 0) {
            let mCveId = this.state.items.cve.cve_data_meta.cve_id;
            return (
                <React.Fragment>
                    <h1>{mCveId}</h1>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.cveData !== undefined) {
            this.productServiceDataSources.getCveIdSourcesInfo(this.props.location.cveData).then(data => this.setState({ items: data }));
        }
    }

    render() {
        const data_id = this.props.location.cveData;
        const breadCrumbItems = [
            {label: 'Data From Sources', url: '/#/dashboard'},
            {label: 'CVEs', url: '/#/cvedata'},
            {label: data_id}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/cvedata');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <div className="card">
                    {this.getCveInfoById()}
                </div>
            </div>
        );
    }
}