import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class MispData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getFormattedThreatLevel(mThreatLevel) {
        if(mThreatLevel === "High"){
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 165, 0, 1)',
                            width: '125px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        Threat Level: {mThreatLevel}
                    </p>
                </React.Fragment>
            );
        } else if(mThreatLevel === "Medium") {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 200, 0, 1)',
                            width: '150px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        Threat Level: {mThreatLevel}
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(60, 179, 113, 1)',
                            width: '125px',
                            borderRadius: '5%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        Threat Level: {mThreatLevel}
                    </p>
                </React.Fragment>
            );
        }
    }

    getMispData(mData) {
        let mAnalysis = mData.analysis;
        let mId = "MISP ID: " + mData.id ;
        let mDate = (mData.publish_timestamp).replace("T", " ");
        let mUuid = mData.uuid;
        let mInfo = mData.info;
        let mTimestamp = (mData.timestamp).replace("T", " ");

        let mThreat = '';
        if(mData.threat_level_id === "1") {
            mThreat = "High";
        } else if(mData.threat_level_id === "2") {
            mThreat = "Medium";
        } else if(mData.threat_level_id === "3") {
            mThreat = "Low";
        }

        return (
            <React.Fragment>
                <Card style={{'background': '#F0F3F6'}} title={mId} subTitle={mDate}>
                    <p>{mInfo}</p>
                    <p style={{'float': 'right'}}>{this.getFormattedThreatLevel(mThreat)}</p>
                </Card>
            </React.Fragment>
        );
    }

    nextPath(path, mispId) {
        this.props.history.push({
            pathname: path,
            MispData: mispId
        });
    }

    componentDidMount() {
        this.productServiceDataSources.getMispData(this.state.session).then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Data From Sources', url: '/#/dashboard'},
            {label: 'MISP Data'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>MISP Data</h1>
                {this.state.items.map((data, index) => {
                    return (
                        <div className="card" key={index} onClick={() => this.nextPath("/MispId", data._id)} style={{cursor: 'pointer'}}>
                            {this.getMispData(data)}
                        </div>
                    )
                })}
            </div>
        );
    }
}