import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class BlacklistId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getFormattedStatus(mStatus) {
        if(mStatus === "online"){
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(60, 179, 113, 1)',
                            width: '50px',
                            borderRadius: '10%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {mStatus}
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p style={
                        {
                            background: 'rgba(255, 0, 0, 1)',
                            width: '50px',
                            borderRadius: '10%',
                            paddingLeft: '5px',
                            color: 'white'
                        }
                    }>
                        {mStatus}
                    </p>
                </React.Fragment>
            );
        }
    }

    getBlacklistInfoById() {
        if(this.state.items.length !== 0) {
            let mId = this.state.items.id;
            let mDate = (this.state.items.date_added).replace("T", " ");
            let mUrl = this.state.items.url;
            let mUrlStatus = this.state.items.url_status;
            let mThreat = this.state.items.threat;
            let mTags = this.state.items.tags;
            let mReporter = this.state.items.reporter;
            let mUrlhause_link = this.state.items.urlhause_link;

            return (
                <React.Fragment>
                    <Card style={{'background': '#FFFFFF'}} title={"Blacklisted Url: " + mUrl}  subTitle={mDate}>
                        {this.getFormattedStatus(mUrlStatus)}
                        <p>Threat: {mThreat} </p>
                        <p>Tags: {mTags} </p>
                        <p>Reporter: {mReporter} </p>
                        <p>Hause Link Url: {mUrlhause_link} </p>
                    </Card>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.cveData !== undefined) {
            this.productServiceDataSources.getBlacklistIdSourcesInfo(this.props.location.cveData).then(data => this.setState({ items: data }));
        }
    }

    render() {
        const data_id = this.state.items.id;
        const breadCrumbItems = [
            {label: 'Data From Sources', url: '/#/dashboard'},
            {label: 'Blacklist', url: '/#/blacklist'},
            {label: data_id}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/blacklist');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <div className="card">
                    {this.getBlacklistInfoById()}
                </div>
            </div>
        );
    }
}