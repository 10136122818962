import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceLicensesInfo from '../service/ServiceLicensesInfo';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';

export class License extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        };

        this.productServiceLicenses = new ServiceLicensesInfo();
        this.deActivateButtonTemplate = this.deActivateButtonTemplate.bind(this);
        this.confirmLicenseActivation = this.confirmLicenseActivation.bind(this);
        this.confirmLicenseDeActivation = this.confirmLicenseDeActivation.bind(this);
    }

    getLicenseState(thisState) {
        let state_src = "../assets/demo/images/state/Red.png";
        if(thisState.state === "active") {
            state_src = "../assets/demo/images/state/Green.png";
        } else if(thisState.state === "in-active") {
            state_src = "../assets/demo/images/state/Red.png";
        }
        return (
            <React.Fragment>
                <img alt="state" src={state_src} width={25} />
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.productServiceLicenses.getLicensesInfo().then(data => this.setState({ products: data }));
    }

    confirmLicenseActivation() {
        console.log("========== confirmLicenseActivation() ==========");
    }

    confirmLicenseDeActivation() {
        console.log("========== confirmLicenseDeActivation() ===========");
    }

    deActivateButtonTemplate(thisState) {
        if(thisState.state === "active") {
            return (
                <React.Fragment>
                    <Button label="Deactivate" icon="pi pi-times" className="p-button-danger p-mr-4" onClick={() => this.confirmLicenseDeActivation()} />
                </React.Fragment>
            )
        } else if(thisState.state === "in-active") {
            return (
                <React.Fragment>
                    <Button label="Activate" icon="pi pi-check" className="p-button-success p-mr-4" onClick={() => this.confirmLicenseActivation()} />
                </React.Fragment>
            )
        }

    }

    render() {
        const breadCrumbItems = [
            {label: 'Administration', url: '/#/dashboard'},
            {label: 'License Management'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Licenses and Service Management</h1>
                <div className="card">
                    <DataTable value={this.state.products}>
                        <Column field="state" header="State" body={this.getLicenseState}></Column>
                        <Column field="name" header="License"></Column>
                        <Column body={this.deActivateButtonTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}