import React, { Component } from 'react';
import './Lander.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import logo from './csir_full_logo.png';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

export class Lander extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUsername: '',
            mPassword: '',
            reportsBusy: false
        };
        this.productServiceDataSources = new ServiceAllApiData();
    }

    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success Message', detail:'Successfully logged in!', life: 5000});
    }

    showError() {
        this.toast.show({severity:'error', summary: 'Error Message', detail:'Unsuccessful login!', life: 5000});
    }

    isProgressBar() {
        if(this.state.reportsBusy === true) {
            return (
                <React.Fragment>
                    <span className="guest-sign-in">Signing in to CTIEP</span>
                    <ProgressSpinner/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <span className="guest-sign-in">Sign in to CTIEP</span>
                </React.Fragment>
            );
        }
    }

    async doReg(path) {
        this.props.history.push({
            pathname: path
        });
    }

    async nextPath(path) {
        const login = {'username': this.state.mUsername, 'password': this.state.mPassword};
        this.setState({reportsBusy: true});
        this.productServiceDataSources.doLogin(login, this.state.mUsername);
        setTimeout(() => {
            if(this.productServiceDataSources.getLoginResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
                this.doReg(path);
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 20000);
    }

    render() {
        return (
            <header>
                <div className="rowToColumn">
                    <Toast ref={(el) => this.toast = el} />
                    <Toast ref={(el) => this.toastTL = el} position="top-left" />
                    <Toast ref={(el) => this.toastBL = el} position="bottom-left" />
                    <Toast ref={(el) => this.toastBR = el} position="bottom-right" />
                    <Toast ref={(el) => this.toastBC = el} position="bottom-center" />
                    <div className="column1 column-zero">
                        <div className="logo-style">
                            <img className="img-logo" src={logo} alt="Logo" />
                            <h1 className="center-text">Cyber Threat Intelligence Exchange Platform</h1>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="column2 column-one">
                        <div className="login-style">
                            <div className="login-panel-content login-cont">
                                <div className="p-grid">
                                    <div className="p-col-3" style={{ textAlign: 'left' }}>
                                        <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                                    </div>
                                    <div className="p-col-9" style={{ textAlign: 'left' }}>
                                        <h2 className="welcome-text">Welcome</h2>
                                        {this.isProgressBar()}
                                    </div>
                                    <div className="p-col-12">
                                        <label className="login-label">Username</label>
                                        <div className="login-input">
                                            <InputText placeholder="username" className="login-input" value={this.state.mUsername} onChange={(e) => this.setState({mUsername: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="login-label">Password</label>
                                        <div className="login-input">
                                            <InputText type="password" placeholder="Password" className="login-input" value={this.state.mPassword} onChange={(e) => this.setState({mPassword: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 button-pane">
                                        <Button label="Sign In" icon="pi pi-sign-in" onClick={(e) => {
                                            e.preventDefault();
                                            this.nextPath('/dashboard');
                                        }} className="login-input sign-in"/>
                                    </div>
                                    <div className="p-col-12 p-md-6 link-pane">
                                        <button className="p-link links justRight">Forgot Password?</button>
                                    </div>
                                    <div className="empty"></div>
                                    <div className="p-col-12 p-md-6 link-pane">
                                        <button className="p-link links">Do not have an account?</button>
                                    </div>
                                    <div className="p-col-12 p-md-6 button-pane">
                                        <Button label="Sign Up" icon="pi pi-plus" onClick={(e) => {
                                            e.preventDefault();
                                            this.doReg('/registration');
                                        }} className="login-input"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
