import axios from 'axios';
import React, { Component } from 'react';

export default class ServiceAllApiData extends Component {
    static dKey = '';
    static mUser = '';
    static mRole = '';
    static mLogin = '';
    static mRegister = '';
    static mAggCvesKeys = '';
    static mAggCvesValues = '';
    static mAggCvesColors = '';
    static mAggMispKeys = '';
    static mAggMispValues = '';
    static mAggMispColors = '';
    static mAggUrlKeys = '';
    static mAggUrlValues = '';
    static mAggUrlColors = '';
    static mAggUrlOnline = '';
    static online = 0;
    static offline = 0;
    static mAuthKey = '';

    async setGlobalKey(myKey) {
        ServiceAllApiData.dKey = myKey;
        this.setCveAggregationData();
        this.setMispAggregationData();
        this.setUrlAggregationData();
        this.setUrlAggregationCoords();
    }

    async getGlobalKey() {
        return ServiceAllApiData.dKey;
    }

    async getAuthKey() {
        return ServiceAllApiData.mAuthKey;
    }

    async setTempLoggedInUser(mUsername) {
        ServiceAllApiData.mUser = mUsername;
    }

    async setLoggedInUser() {
        let mKeyResult = ServiceAllApiData.dKey;
        let mUserFullName = ' ';
        let mUserRole = "Admin";
        await axios.get(window.location.href + '/capi/v1/rest-auth/user/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => {
            mUserFullName = res.data.first_name + " " + res.data.last_name;
            ServiceAllApiData.mUser = mUserFullName;
            ServiceAllApiData.mRole = mUserRole;
        });
    }

    async doLogin(login, username) {
        let mKey = "";
        let mResult = '';
        await Promise.all([
            axios.post(window.location.href + '/capi/v1/rest-auth/login/', login).then(res => {
                mKey = res.data.key;
                mResult = res.request.status;
                if(mResult === 200 || mResult === 201) {
                    this.setGlobalKey(mKey);
                    this.setLoggedInUser(username);
                    this.setLoggedInUser();
                    ServiceAllApiData.mLogin = "success";
                } else {
                    ServiceAllApiData.mLogin = "unsuccess";
                }
            }),
        ]);
    }

    async setCveAggregationData() {
        await Promise.all([
            axios.get(window.location.href + '/capi/v1/cves/severity_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.dKey)}}).then((res) => {
                let key = [];
                let value = [];
                let severity = [];
                let aggCves = res.data.results;
                setTimeout(() => {
                    let severity_levels = aggCves;
                    if(severity_levels !== undefined) {
                        severity_levels.map((severity_level) => {
                            if(severity_level.id === ""){
                                key.push("INFO");
                            } else {
                                key.push(severity_level.id);
                            }
                            value.push(severity_level.total);

                            if(severity_level.id === "") {
                                severity.push('rgba(0, 0, 255, 1)');
                            }else if(severity_level.id === "CRITICAL") {
                                severity.push('rgba(255, 0, 0, 1)');
                            }else if(severity_level.id === "HIGH") {
                                severity.push('rgba(255, 165, 0, 1)');
                            }else if(severity_level.id === "MEDIUM") {
                                severity.push('rgba(255, 200, 0, 1)');
                            }else if(severity_level.id === "LOW") {
                                severity.push('rgba(60, 179, 113, 1)');
                            }
                        });
                    }
                    ServiceAllApiData.mAggCvesKeys = key;
                    ServiceAllApiData.mAggCvesValues = value;
                    ServiceAllApiData.mAggCvesColors = severity;
                }, 2000 );
            }),
        ]);
    }

    async setMispAggregationData() {
        await Promise.all([
            axios.get(window.location.href + '/capi/v1/misp/threat_level_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.dKey)}}).then((res) => {
                let key = [];
                let value = [];
                let color = [];
                let aggMisp = res.data.results;
                setTimeout(() => {
                    let threat_levels = aggMisp;
                    if(threat_levels !== undefined) {
                        threat_levels.map((threat_level) => {
                            if(threat_level.id === "1") {
                                key.push("HIGH");
                                color.push('rgba(255, 165, 0, 1)');
                            } else if(threat_level.id === "2") {
                                key.push("MEDIUM");
                                color.push('rgba(255, 200, 0, 1)');
                            } else if(threat_level.id === "3") {
                                key.push("LOW");
                                color.push('rgba(60, 179, 113, 1)');
                            } else if(threat_level.id === "4") {
                                key.push("UNDEFINED");
                                color.push('rgba(0, 0, 255, 1)');
                            }
                            value.push(threat_level.total);
                        });
                    }
                    ServiceAllApiData.mAggMispKeys = key;
                    ServiceAllApiData.mAggMispValues = value;
                    ServiceAllApiData.mAggMispColors = color;
                }, 2000 );
            }),
        ]);
    }

    async setUrlAggregationData() {
        console.log("window.location.href: ", window.location.href);
        await Promise.all([
            axios.get(window.location.href + '/capi/v1/urlblacklist/url_status_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.dKey)}}).then((res) => {
                let key = [];
                let value = [];
                let color = [];
                let aggUrl = res.data.results;
                setTimeout(() => {
                    let urls = aggUrl;
                    if(urls !== undefined) {
                        urls.map((url) => {
                            key.push(url.status);
                            value.push(url.total);
                            if(url.id === "online") {
                                color.push('rgba(60, 179, 113, 1)');
                                ServiceAllApiData.online = ServiceAllApiData.online + 1;
                            } else {
                                color.push('rgba(255, 0, 0, 1)');
                                ServiceAllApiData.offline = ServiceAllApiData.offline + 1;
                            }
                        });
                    }
                    ServiceAllApiData.mAggUrlKeys = key;
                    ServiceAllApiData.mAggUrlValues = value;
                    ServiceAllApiData.mAggUrlColors = color;
                }, 2000 );
            }),
        ]);
    }

    async setUrlAggregationCoords() {
        await Promise.all([
            axios.get(window.location.href + '/capi/v1/urlblacklist/url_location_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.dKey)}}).then((res) => {
                let latLon = [];
                let urlLocations = res.data.results;
                setTimeout(() => {
                    let locations = urlLocations;
                    if(locations !== undefined) {
                        locations.map((location) => {
                            latLon.push(
                                {
                                    'lat': location.lat,
                                    'lon': location.lon,
                                    'country': location.country,
                                    'detect': location.num_of_detections,
                                    'city': location.city,
                                    'ip': location.ip,

                                }
                            );
                        });
                    }
                    ServiceAllApiData.mAggUrlCoords = latLon;
                }, 5000 );
            }),
        ]);
    }

    getLoginResult() {
        if(ServiceAllApiData.mLogin === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async doEmail(email, firstName, lastName, useKey) {
        console.log(email);
        console.log(firstName);
        console.log(lastName);
        console.log(useKey);
        await Promise.all([
            axios.get(window.location.href + '/capi/v1/accounts/send-email/?first_name=' + firstName + '&last_name=' + lastName + '&key=' + useKey + '&email=' + email, {'headers': {'Authorization': 'Token '.concat(useKey)}}).then((res) => {console.log("Email was sent!")}),
        ]);
    }

    async doRegistration(registration) {
        let mResult = '';
        await Promise.all([
            axios.post(window.location.href + '/capi/v1/rest-auth/registration/', registration).then(res => {
                ServiceAllApiData.mAuthKey = res.data.key;
                mResult = res.request.status
                if(mResult === 200 || mResult === 201) {
                    ServiceAllApiData.mRegister = "success";
                    this.doEmail(registration.email, registration.first_name, registration.last_name, ServiceAllApiData.mAuthKey);
                } else {
                    ServiceAllApiData.mRegister = "unsuccess";
                }
            }),
        ]);
    }

    getRegResult() {
        if(ServiceAllApiData.mRegister === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    getLoggedInUser() {
        return ServiceAllApiData.mUser;
    }

    getLoggedInUserRole() {
        return ServiceAllApiData.mRole;
    }

    getSiemEventInfo() {
        return axios.get('assets/demo/data/siem-events.json').then((res) => res.data.data);
    }

    getIncidentsInfo() {
        return axios.get('assets/demo/data/incidents.json').then((res) => res.data.data);
    }

    getDataSourcesInfo() {
        return axios.get('assets/demo/data/data-sources.json').then((res) => res.data.data);
    }

    getNotifications() {
        return axios.get('assets/demo/data/notifications.json').then((res) => res.data.data);
    }

    getReportingData() {
        return axios.get('assets/demo/data/reporting.json').then((res) => res.data.data);
    }

    getCveAggregationKeys() {
        return ServiceAllApiData.mAggCvesKeys;
    }

    getCveAggregationValues() {
        return ServiceAllApiData.mAggCvesValues;
    }

    getCveAggregationColors() {
        return ServiceAllApiData.mAggCvesColors;
    }

    getMispAggregationKeys() {
        return ServiceAllApiData.mAggMispKeys;
    }

    getMispAggregationValues() {
        return ServiceAllApiData.mAggMispValues;
    }

    getMispAggregationColors() {
        return ServiceAllApiData.mAggMispColors;
    }

    getUrlAggregationKeys() {
        return ServiceAllApiData.mAggUrlKeys;
    }

    getUrlAggregationValues() {
        return ServiceAllApiData.mAggUrlValues;
    }

    getUrlAggregationOnline() {

        return ServiceAllApiData.online;
    }

    getUrlAggregationOffline() {
        return ServiceAllApiData.offline;
    }

    getUrlAggregationColors() {
        return ServiceAllApiData.mAggUrlColors;
    }

    getUrlAggregationCoords() {
        return ServiceAllApiData.mAggUrlCoords;
    }

    async getMispData() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/misp/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    async getCveDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/cves/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    async getOperationalReportsData() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/reports/operational/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getTacticalReportsData() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/reports/tactical/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getStrategicReportsData() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/reports/strategic/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getTacticalReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/reports/tactical/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getOperationalReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/reports/operational/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getStrategicReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/reports/strategic/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getCveIdSourcesInfo(cveId) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/cves/'.concat(cveId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res)  => res.data);
    }

    async getMispIdSourcesInfo(mispId) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/misp/'.concat(mispId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getMalwareDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/malware/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res)  => res.data.results);
    }

    async getBlacklistDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/urlblacklist/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res)  => res.data.results);
    }

    async getMalwareIdSourcesInfo(malwareId) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/malware/'.concat(malwareId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res)  => res.data);
    }

    async getBlacklistIdSourcesInfo(blacklistId) {
        let mKeyResult = ServiceAllApiData.dKey;
        return axios.get(window.location.href + '/capi/v1/urlblacklist/'.concat(blacklistId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getIndustriesInfo() {
        return axios.get(window.location.href + '/capi/v1/accounts/industries/').then(res => res.data);
    }

    async getProvincesInfo() {
        return axios.get(window.location.href + '/capi/v1/accounts/provinces/').then(res => res.data);
    }

    useGlobalAdmin() {
        let mLoggedInUser = ServiceAllApiData.mUser + ' ';
        return (
            <React.Fragment>
                <span className="profile-name">{mLoggedInUser}</span>
            </React.Fragment>
        );
    }
}