import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class OperationalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getAvailableList() {
        if(this.state.items.length === 0) {
            return (
                <React.Fragment>
                    <h6>No stakeholder has shared any data with you.</h6>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {this.state.items.map((data, index) => {
                        return (
                            <div className="card" key={index} onClick={() => this.nextPath("/operational", data.id)} style={{cursor: 'pointer'}}>
                                {this.getReports(data)}
                            </div>
                        )
                    })}
                </React.Fragment>
            );
        }
    }

    getReports(mData) {
        let mId = mData.id;
        let mReportId = mData.information.report_id;
        let mCompanyName = mData.company.name;
        let mRegNumber = mData.company.registration_number;
        let mIncidentLevel = mData.incident_level.threat_level;
        mIncidentLevel = mIncidentLevel.slice(0, -1);
        mIncidentLevel = mIncidentLevel.substring(mIncidentLevel.indexOf("=") + 1);
        let mDate = (mData.information.creation_time).replace("T", " ");
        let mProvince = mData.company.province;
        let mIndustry = mData.company.industry;
        let mTimeFrom = (mData.information.time_from).replace("T", " ");;
        let mTimeTo = (mData.information.time_to).replace("T", " ");;
        let mTime = mTimeFrom + " | " + mTimeTo;
        return (
            <React.Fragment>
                <Card style={{'background': '#F0F3F6'}} title={mCompanyName} subTitle={mDate}>
                    <h6>{mProvince} | {mIndustry}</h6>
                    <hr/>
                    <div className="p-md-12">
                        <p>Incident Level: {mIncidentLevel}</p>
                        <p>Company Registration: {mRegNumber}</p>
                        <p>Time From: {mTimeFrom}</p>
                        <p>Time To: {mTimeTo}</p>
                    </div>
                </Card>
            </React.Fragment>
        );
    }

    nextPath(path, report) {
        this.props.history.push({
            pathname: path+"/"+report,
            report: report
        });
    }

    componentDidMount() {
        this.productServiceDataSources.getOperationalReportsData(this.state.session).then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Reporting', url: '/#/dashboard'},
            {label: 'Operational List'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/dashboard');
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Operational Reports</h1>
                {this.getAvailableList()}
            </div>
        );
    }
}